.newline {
  white-space: pre-wrap;
}

#portfolio h1 {
  font-size: 2rem;
}

.skills__container {
  display: flex;
}

.project__icons {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
/* .project__img {
  width: 400px;
  height: 400px;
} */

.feature-item {
  text-align: center;
  margin-top: 2.5em;
  padding-left: 2em;
  padding-right: 2em;
}

.center {
  text-align: center;
}

.warning {
  color: red;
}
